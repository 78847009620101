<template>
  <section
    id="customer-logos"
    class="relative"
  >
    <!-- Right Glow -->
    <div class="absolute pointer-events-none right-0 -top-16">
      <img
        src="/img/pages/welcome/banner-right-glow.png"
        alt="left-glow"
      >
    </div>
    <div class="max-w-screen-xl mx-auto py-6 text-center">
      <p class="text-gray-400 font-medium text-sm uppercase">
        Trusted by {{ appStore.userCount }}+ users and companies
      </p>
      <div class="mt-6 max-w-4xl mx-auto">
        <img
          src="/img/pages/welcome/companies.png"
          alt="companies"
          class="hidden md:block mx-auto"
        >
        <img
          src="/img/pages/welcome/companies-mobile.png"
          alt="companies"
          class="block md:hidden mx-auto"
        >
      </div>
      <div class="my-8">
        <object
          data="/img/pages/welcome/hr-line.svg"
          type="image/svg+xml"
          class="block mx-auto w-full"
        />
      </div>
    </div>
  </section>
</template>


<script setup>
const appStore = useAppStore()
</script>

<template>
  <section
    class="flex flex-col items-start p-6 mt-12 text-sm leading-4 rounded-2xl border border-solid bg-slate-50 border-slate-200 max-md:max-w-full">
    <h2 class="text-xl font-semibold text-gray-900 max-md:max-w-full">
      Looking for a custom plan?
    </h2>
    <ul
      class="flex flex-col flex-wrap content-start pr-4 mt-8 max-w-full leading-[150%] text-slate-700 w-[560px] text-gray-700">
      <li class="flex gap-5 justify-between max-md:flex-wrap">
        <span class="flex gap-1">
          <Icon
            name="heroicons:check-circle"
            class="w-5 h-5"
          />
          <span>Custom file upload limit</span>
        </span>
        <span class="flex gap-1">
          <Icon
            name="heroicons:check-circle"
            class="w-5 h-5"
          />
          <span>Enterprise level support</span>
        </span>
        <span class="flex gap-1">
          <Icon
            name="heroicons:check-circle"
            class="w-5 h-5"
          />
          <span>Custom contract</span>
        </span>
      </li>
      <li class="flex gap-5 justify-between self-start mt-6">
        <span class="flex gap-1">
          <Icon
            name="heroicons:check-circle"
            class="w-5 h-5"
          />
          <span>Payment via invoice/PO</span>
        </span>
        <span class="justify-center">...and more</span>
      </li>
    </ul>
    <v-button
      color="white"
      class="justify-center mt-6 font-medium text-center rounded-xl border border-solid border-slate-200 text-slate-800"
      @click.prevent="customPlanClick"
    >
      Contact us
    </v-button>
  </section>
</template>

<script setup>
const customPlanClick = () => {
  useCrisp().sendTextMessage(
    'Hi, I would like to discuss about a custom plan'
  )
}
</script>
